import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { HttpError } from 'ra-core';
import React from 'react';
import {
  Button,
  Form,
  number,
  NumberInput,
  required,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  Create
} from 'react-admin';
import { useFormState } from 'react-hook-form';

import { dateValidator } from '../../../../forms/validators';
import useDialog from '../../../../hooks/useDialog';

type Props = {
  onChange: () => void;
  agreementId: string;
};

type Transaction = { id: string; amount: number; date: string; orderId: string };

const SubmitButton = (props: { isLoading: boolean }) => {
  const { isValid } = useFormState();

  return <SaveButton saving={props.isLoading} disabled={props.isLoading || !isValid} />;
};

function CreateTransaction({ onChange, agreementId }: Props) {
  const [isDialogOpen, openDialog, closeDialog] = useDialog();
  const [create, { isLoading }] = useCreate<Transaction, HttpError>();
  const notify = useNotify();

  const handleSubmit = (data: Transaction) => {
    create(
      `credit/${agreementId}/transaction`,
      { data },
      {
        onSuccess: () => {
          closeDialog();
          onChange();
        },
        onError: error => {
          notify(error.body.error.message, { type: 'error' });
        }
      }
    );
  };

  return (
    <>
      <Button onClick={openDialog} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={isDialogOpen} onClose={closeDialog} aria-label="Create post">
        <DialogTitle>Create Transaction</DialogTitle>
        <Create>
          <Form
            resource="transaction"
            onSubmit={data => handleSubmit(data as Transaction)}
            mode="onBlur"
            reValidateMode="onBlur"
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <NumberInput
                    source="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    validate={[required(), number()]}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextInput
                    source="date"
                    label="Transaction date (YYYYMMDD)"
                    fullWidth
                    validate={[required(), dateValidator('Enter a valid date')]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextInput source="orderId" label="Reference" fullWidth validate={required()} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={closeDialog} disabled={isLoading}>
                <IconCancel />
              </Button>
              <SubmitButton isLoading={isLoading} />
            </DialogActions>
          </Form>
        </Create>
      </Dialog>
    </>
  );
}

export default CreateTransaction;
