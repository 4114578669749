import React, { ReactElement } from 'react';
import { FunctionField, FunctionFieldProps, NumberField } from 'react-admin';

import { getCountryCurrency, getCountryLocale } from '../localization/constants';
import { CreditResource } from '../types';

const CurrencyField = (props: Omit<FunctionFieldProps, 'render'>): ReactElement => (
  <FunctionField
    render={(record: CreditResource) => {
      if (!record?.country) {
        return null;
      }

      return (
        <NumberField
          style={{ whiteSpace: 'nowrap' }}
          options={{ style: 'currency', currency: `${getCountryCurrency(record.country)}` }}
          source={props.source}
          locales={getCountryLocale(record.country)}
        />
      );
    }}
    {...props}
  />
);

export default CurrencyField;
