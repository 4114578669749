import { useState } from 'react';

const useDialog = (initialState = false): [boolean, () => void, () => void] => {
  const [isDialogOpen, setIsDialogOpen] = useState(initialState);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return [isDialogOpen, openDialog, closeDialog];
};

export default useDialog;
