import React, { ReactElement } from 'react';
import { Datagrid, FunctionField } from 'react-admin';

import CurrencyField from '../../../../components/CurrencyField';
import { Country } from '../../../../types';
import {
  isCreditPaybackItem,
  isDebtConsolidationPaybackItem,
  isTransactionItem,
  PaybackItem,
  PaybackItemType
} from '../../../../types/paymentPlan';

type Props = {
  items: PaybackItem[];
  country: Country;
};

const formatProductType = (item: PaybackItem): string => {
  let productPrefix = '';
  if (isCreditPaybackItem(item)) {
    productPrefix = 'VARDA';
  } else if (isDebtConsolidationPaybackItem(item)) {
    productPrefix = 'LÖSA';
  }

  let productItemType = item.type as string;
  if (productPrefix) {
    switch (item.type) {
      case PaybackItemType.PAYBACK_INTEREST:
        productItemType = `${productPrefix}_INTEREST`;
        break;
      case PaybackItemType.PAYBACK_PRINCIPAL:
        productItemType = `${productPrefix}_PRINCIPAL`;
        break;
    }
  }

  return productItemType;
};

const PaymentPlanItemList = ({ items, country }: Props): ReactElement | null => {
  const paybackItemDetails = items
    .map(item => ({
      amount: item.amount,
      amountPaid: !isTransactionItem(item) ? item.amountPaid : undefined,
      type: formatProductType(item)
    }))
    .sort((a, b) => (a.type > b.type ? -1 : 1));

  return (
    <FunctionField
      label="Details"
      render={() => (
        <Datagrid
          data={paybackItemDetails}
          bulkActionButtons={false}
          size="small"
          sx={{ '& .RaDatagrid-headerRow': { display: 'none' }, whiteSpace: 'nowrap' }}
        >
          <CurrencyField label="" source="amount" record={{ country }} />
          <CurrencyField label="" source="amountPaid" record={{ country }} />
          <FunctionField source="type" render={formatProductType} />
        </Datagrid>
      )}
    />
  );
};

export default PaymentPlanItemList;
