import { Card, Divider } from '@mui/material';
import Paper from '@mui/material/Paper';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  TopToolbar,
  useRecordContext,
  useRefresh
} from 'react-admin';

import CurrencyField from '../../../components/CurrencyField';
import RequireUserCountry from '../../../components/RequireUserCountry';
import { CreditResourceDetails } from '../../../types';
import { TransactionDetails } from '../../../types/paymentPlan';
import PaymentPlanItemList from '../components/PaymentPlanItemList';
import CreateTransaction from './components/CreateTransaction';
import RegisterManualPayment from './components/RegisterManualPayment';
import TransactionTypeField from './components/TransactionTypeField';

const Transactions = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CreditResourceDetails>();

  return record?.plan?.transactions ? (
    <Card>
      <List
        resource="transactions"
        exporter={false}
        pagination={false}
        component={Paper}
        title={<></>}
        actions={
          <RequireUserCountry record={record.user} countries={['SE']}>
            <TopToolbar sx={{ alignItems: 'center', paddingRight: '8px !important', gap: '16px' }}>
              <RegisterManualPayment />
              <Divider sx={{ height: 22, m: 0.5 }} orientation="vertical" />
              <CreateTransaction agreementId={record?.agreement.id} onChange={refresh} />
            </TopToolbar>
          </RequireUserCountry>
        }
        sx={{ width: '100%' }}
        queryOptions={{ enabled: false }}
      >
        <Datagrid
          data={record.plan.transactions}
          bulkActionButtons={false}
          size="small"
          aria-label="transaction table"
          sx={{ minWidth: '650px' }}
        >
          <TextField source="dueDate" sortable={false} />
          <TextField source="paybackId" label="Invoice" sortable={false} />
          <TransactionTypeField label="Type" sortable={false} />
          <CurrencyField source="amount" record={{ country: record.agreement.country, ...record }} />
          <TextField source="status" sortable={false} />
          <TextField source="account" sortable={false} />
          <TextField source="transactionDate" sortable={false} />
          <FunctionField
            label="Details"
            render={(transaction: TransactionDetails) => (
              <PaymentPlanItemList
                key={transaction.paybackId}
                items={transaction.items}
                country={record.agreement.country}
              />
            )}
          />
        </Datagrid>
      </List>
    </Card>
  ) : null;
};

export default Transactions;
