import React from 'react';
import { Layout } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';

import AppBar from './AppBar';

const UpdateLayout = (props: any) => {
  return (
    <>
      <Layout {...props} appBar={AppBar} />
      <ReactQueryDevtools />
    </>
  );
};
export default UpdateLayout;
