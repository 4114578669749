import { z } from 'zod';

import { environment } from '../Config';

export const validateResponseData = <T extends z.ZodTypeAny>(schema: T, data: unknown) => {
  const result = schema.safeParse(data);

  if (!result.success) {
    if (environment.env !== 'production') {
      // eslint-disable-next-line no-console
      console.error(result.error);
    }
    //TODO: Send error to Sentry
  }

  // We cast data because we don't want the application to crash if the data is invalid
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data as z.infer<T>;
};
