import { useQuery, UseQueryOptions } from 'react-query';

import API from '../../../../../../../api';
import { UsersAuthenticateByOrderIdResponse } from '../../../../../../../api/user/types';
import QUERY_KEYS from '../../../../../../../constants/QueryKeys';

const useUsersAuthenticateByOrderIdQuery = <TData = UsersAuthenticateByOrderIdResponse>(
  { userId, orderId }: { userId: string; orderId: string },
  options?: UseQueryOptions<UsersAuthenticateByOrderIdResponse, unknown, TData>
) =>
  useQuery({
    queryKey: QUERY_KEYS.userAuthenticate({ userId, orderId }),
    queryFn: () => API.user.authenticateUserByOrderId({ userId, orderId }),
    ...options
  });

export default useUsersAuthenticateByOrderIdQuery;
