import { z } from 'zod';

export const pendingStatusSchema = z
  .object({
    status: z.literal('pending'),
    hintCode: z.enum([
      'outstandingTransaction',
      'noClient',
      'started',
      'userMrtd',
      'userCallConfirm',
      'userSign',
      'UNKNOWN'
    ])
  })
  .catch({ status: 'pending', hintCode: 'UNKNOWN' });

export const failedStatusSchema = z
  .object({
    status: z.literal('failed'),
    hintCode: z.enum([
      'expiredTransaction',
      'certificateErr',
      'userCancel',
      'cancelled',
      'startFailed',
      'userDeclinedCall',
      'UNKNOWN'
    ])
  })
  .catch({ status: 'failed', hintCode: 'UNKNOWN' });

export const completeStatusSchema = z.object({ status: z.literal('complete') });

export const bankIdStatusResponseSchema = z.union([
  completeStatusSchema,
  pendingStatusSchema,
  failedStatusSchema
]);
