import { useQuery, UseQueryOptions } from 'react-query';

import API from '../../../../../../../api';
import { BankIdStatusResponse } from '../../../../../../../api/external/bankId/types';
import QUERY_KEYS from '../../../../../../../constants/QueryKeys';

const useBankIdStatusQuery = <TData = BankIdStatusResponse>(
  orderId: string,
  options?: UseQueryOptions<BankIdStatusResponse, unknown, TData>
) =>
  useQuery({
    queryKey: QUERY_KEYS.bankIdStatus(orderId),
    queryFn: () => API.external.bankId.getBankIdStatus(orderId),
    ...options
  });

export default useBankIdStatusQuery;
