import { Box } from '@mui/material';
import React from 'react';
import { TextInput } from 'react-admin';

const BankDetailsSE = () => (
  <>
    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput
          source="user.paymentProvider"
          label="Payment provider"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.bankName"
          label="Bank"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.accountNumber"
          label="Account #"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.directDebitMandate"
          label="Direct debit mandate"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.directPayout"
          label="Direct withdrawal"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
    </Box>
  </>
);

export default BankDetailsSE;
