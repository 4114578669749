import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalOfferIcon from '@mui/icons-material/LocalOfferTwoTone';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { Button, Confirm, useNotify, useRecordContext } from 'react-admin';

import useUserActionMutation from '../../../../hooks/useUserActionMutation';
import { CreditResourceDetails } from '../../../../types';
import { formatDate } from '../../../../utils/date';

type Props = {
  onSuccess: () => void;
};

const PaymentFreeMonthButton = ({ onSuccess }: Props) => {
  const record = useRecordContext<CreditResourceDetails>();
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(false);

  const {
    mutate: activatePaymentFreeMonth,
    isLoading: isActivatePaymentFreeMonthLoading,
    isSuccess: isActivatePaymentFreeMonthSuccess
  } = useUserActionMutation(record.user.id, {
    onSuccess: () => {
      notify('Payment free month activated!', { type: 'success' });
      onSuccess();
    },
    onError: error => {
      notify(error.body.error.message, { type: 'error' });
    }
  });

  const { isEligible, lastOfferDate, lastCompletionDate, ineligibilityReason } =
    record.customerSupportActions?.paymentFreeMonthOffer || {};

  const isDisabled = !isEligible || isActivatePaymentFreeMonthLoading || isActivatePaymentFreeMonthSuccess;

  return (
    <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
      <Tooltip title={ineligibilityReason}>
        <Box>
          <Button
            label="Payment free month"
            disabled={isDisabled}
            onClick={() => setIsOpen(true)}
            variant="outlined"
          >
            <LocalOfferIcon />
          </Button>
        </Box>
      </Tooltip>

      {lastOfferDate ? (
        <Tooltip
          title={
            <Box>
              <span>Last offer date: {formatDate(new Date(lastOfferDate), 'DD-MM-YY')}</span>
              <br />
              <span>
                Last completion date:{' '}
                {lastCompletionDate ? formatDate(new Date(lastCompletionDate), 'DD-MM-YY') : '-'}
              </span>
            </Box>
          }
        >
          <EventAvailableIcon color="success" />
        </Tooltip>
      ) : null}
      <Confirm
        isOpen={isOpen}
        loading={isActivatePaymentFreeMonthLoading}
        title="Activate payment free month"
        content="Are you sure you want to activate a payment free month for this customer?"
        onConfirm={() => {
          activatePaymentFreeMonth({ type: 'PAYMENT_FREE_MONTH' });
          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  );
};

export default PaymentFreeMonthButton;
