import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { Button, Confirm, useNotify, useRecordContext } from 'react-admin';

import useUserActionMutation from '../../../../hooks/useUserActionMutation';
import { CreditResourceDetails } from '../../../../types';
import { formatDate } from '../../../../utils/date';

type Props = {
  onSuccess: () => void;
};

const ResetPaybackPlanButton = ({ onSuccess }: Props) => {
  const record = useRecordContext<CreditResourceDetails>();
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(false);

  const {
    mutate: resetPaybackPlan,
    isLoading: isResetPaybackPlanLoading,
    isSuccess: isResetPaybackPlanSuccess
  } = useUserActionMutation(record.user.id, {
    onSuccess: () => {
      notify("User's payback plan has been reset", { type: 'success' });
      onSuccess();
    },
    onError: error => {
      notify(error.body.error.message, { type: 'error' });
    }
  });

  const { isEligible, lastOfferDate, lastCompletionDate } =
    record.customerSupportActions?.resetPaybackPlanOffer || {};

  return (
    <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
      <Button
        disabled={!isEligible || isResetPaybackPlanLoading || isResetPaybackPlanSuccess}
        onClick={() => setIsOpen(true)}
        label="Reset Payback plan"
        variant="outlined"
      >
        <RestartAltIcon />
      </Button>

      {lastOfferDate ? (
        <Tooltip
          title={
            <Box>
              <span>Last offer date: {formatDate(new Date(lastOfferDate), 'DD-MM-YY')}</span>
              <br />
              <span>
                Last completion date:{' '}
                {lastCompletionDate ? formatDate(new Date(lastCompletionDate), 'DD-MM-YY') : '-'}
              </span>
            </Box>
          }
        >
          <EventAvailableIcon color="success" />
        </Tooltip>
      ) : null}
      <Confirm
        isOpen={isOpen}
        loading={isResetPaybackPlanLoading}
        title="Activate reset payback plan"
        content="Are you sure you want to reset the payback plan for this customer?"
        onConfirm={() => {
          resetPaybackPlan({ type: 'RESET_PAYBACK_PLAN' });
          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  );
};

export default ResetPaybackPlanButton;
