import React from 'react';
import {
  Toolbar,
  SaveButton,
  Edit,
  FormDataConsumer,
  BooleanInput,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';

import RequireUserCountry from '../components/RequireUserCountry';
import { Block } from '../types/block';

const PostEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const BlockEdit = () => {
  return (
    <Edit>
      <SimpleForm toolbar={<PostEditToolbar />}>
        <FormDataConsumer>
          {({ formData }: Omit<Block, 'id'>) => (
            <>
              <RequireUserCountry record={formData} countries={['SE']}>
                <TextInput
                  source="nationalNumber"
                  label="National number"
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />
              </RequireUserCountry>
              <RequireUserCountry record={formData} countries={['UK']}>
                <TextInput
                  source="email"
                  label="Email"
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />
              </RequireUserCountry>
              <TextInput source="comment" multiline resettable validate={required()} fullWidth />
              <BooleanInput label="Active" source="active" defaultValue={!formData.deactivated} />
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default BlockEdit;
