import { Auth } from 'aws-amplify';
import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

const authProvider = (type: any, params: any) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    return Auth.federatedSignIn({ customProvider: 'IdP' });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    return Auth.signOut();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      // eslint-disable-next-line no-console
      console.error('ERROR', status);
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return Auth.currentSession();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Auth.currentSession();
  }
  return Promise.reject('Unknown method');
};

export default authProvider;
