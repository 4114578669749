/**
 * Map React Hook Form's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
 * @param dirtyFields the property returned from useFormState
 * @param allValues all the form fields with their values
 */
export function dirtyValues(dirtyFields: object | boolean, allValues: object): object {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      dirtyValues((dirtyFields as Record<string, any>)[key], (allValues as Record<string, any>)[key])
    ])
  );
}
