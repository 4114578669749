import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Box, Button, Snackbar, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';

const CopyToClipboardComponent = ({ text }: { text: string }) => {
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <TextField
          label="Copy url"
          variant="outlined"
          value={text}
          size="small"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Copy to Clipboard" arrow>
                <Button size="small" onClick={handleCopy} style={{ minWidth: 'unset' }}>
                  <FileCopyOutlinedIcon />
                </Button>
              </Tooltip>
            )
          }}
          sx={{ marginRight: 1 }}
        />
      </Box>
      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose} message="Copied to clipboard" />
    </div>
  );
};

export default CopyToClipboardComponent;
