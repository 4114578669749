const QUERY_KEYS = {
  bankIdStatus: (orderId: string) => ['bankIdStatus', orderId],
  manualPayment: (aviNumber: string) => ['manual-payment', aviNumber],
  ukManualReviewDeclineCodes: ['UK-decline-codes'],
  ukProductTiers: ['UK-product-tiers'],
  userAuthenticate: ({ userId, orderId }: { userId: string; orderId: string }) => [
    'user-authenticate',
    userId,
    orderId
  ]
};

export default QUERY_KEYS;
