import { Cancel as CancelIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Button as MuiButton,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';

export type FormValues = { nationalNumber: string; callInitiator: 'RP' | 'user' };

const Info = styled('div')({
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '300px'
});

type Params = {
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
};

const LegalGuardianModal = ({ onClose, onSubmit }: Params) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<FormValues>({
    mode: 'onBlur'
  });

  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Legal Guardian Info</DialogTitle>
        <DialogContent>
          <Info>
            <Box width={320} gap="20px" display="flex" flexDirection="column">
              <TextField
                {...register('nationalNumber', {
                  required: 'The personal number is required',
                  pattern: {
                    value: /^(19|20)\d{10}$/,
                    message: 'Invalid personal number (YYYYMMDDXXXX)'
                  }
                })}
                label="Personal Number"
                variant="outlined"
                fullWidth
                error={!!formErrors.nationalNumber}
                helperText={formErrors.nationalNumber?.message}
              />

              <FormControl component="fieldset" error={!!formErrors.callInitiator}>
                <FormLabel component="legend">Who initiated the call?</FormLabel>
                <Controller
                  control={control}
                  name="callInitiator"
                  //@ts-expect-error - Fixes react warning about switching from controlled to uncontrolled component
                  defaultValue=""
                  rules={{ required: 'Please select' }}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel value="user" control={<Radio />} label="The legal guardian" />
                      <FormControlLabel value="RP" control={<Radio />} label="Fairlo" />
                    </RadioGroup>
                  )}
                />
                {formErrors.callInitiator && (
                  <FormHelperText>{formErrors.callInitiator.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
          </Info>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.close" onClick={onClose}>
            <CancelIcon />
          </Button>
          <MuiButton type="submit" startIcon={<SaveIcon />} variant="contained">
            Authenticate
          </MuiButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LegalGuardianModal;
