import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';
import { Create, email, maxLength, minLength, required, SimpleForm, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import useAuthCountries from '../hooks/useAuthCountries';
import { CountryConfig } from '../localization/constants';
import { Country } from '../types';

type CountrySelectProps = {
  countries: Country[];
  value: Country;
  onChange: (country: Country) => void;
};

const CountrySelect = ({ countries, value, onChange }: CountrySelectProps) => {
  const { reset } = useFormContext();

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as Country);
    reset();
  };

  return (
    <FormControl sx={{ minWidth: 80 }}>
      <InputLabel id="country-label">Country</InputLabel>
      <Select label="Country" labelId="country-label" onChange={handleChange} value={value}>
        {countries.map(country => (
          <MenuItem value={country} key={country}>
            {CountryConfig[country].flag}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const BlockCreate = () => {
  const authCountries = useAuthCountries();
  const [country, setCountry] = useState<Country>(authCountries[0] || 'SE');

  return (
    <Create>
      <SimpleForm>
        {authCountries.length > 1 ? (
          <CountrySelect countries={authCountries} value={country} onChange={setCountry} />
        ) : null}
        {country === 'SE' ? (
          <TextInput
            source="nationalNumber"
            fullWidth
            label="National number YYYYMMDDXXXX"
            validate={[maxLength(12), minLength(12)]}
          />
        ) : null}
        {country === 'UK' ? (
          <TextInput source="email" type="email" label="Email" fullWidth validate={email()} />
        ) : null}
        <TextInput source="comment" fullWidth multiline validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default BlockCreate;
