import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useNotify, useRecordContext, useRefresh } from 'ra-core';
import { useState } from 'react';
import { useMutation } from 'react-query';

import API from '../../../api';
import { CreditResourceDetails } from '../../../types';

const TerminationButton = () => {
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const record = useRecordContext<CreditResourceDetails>();

  const { mutate: terminateInsurance, isLoading: isTerminateInsuranceLoading } = useMutation({
    mutationFn: API.paymentProtectionInsurance.terminateInsurance,
    onSuccess: () => {
      refresh();
      setOpen(false);
    },
    onError: () => {
      notify('Something went wrong terminating the insurance', { type: 'error' });
    }
  });

  const { contractId } = record.paymentProtectionInsurance;

  if (!contractId) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        size="small"
        color="error"
        disabled={isTerminateInsuranceLoading}
        startIcon={
          isTerminateInsuranceLoading ? (
            <CircularProgress size="14px" color="inherit" />
          ) : (
            <DoNotDisturbOnIcon />
          )
        }
      >
        Terminate
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to terminate?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => terminateInsurance(contractId)}
            color="error"
            disabled={isTerminateInsuranceLoading}
            startIcon={
              isTerminateInsuranceLoading ? (
                <CircularProgress size="14px" color="inherit" />
              ) : (
                <DoNotDisturbOnIcon />
              )
            }
          >
            Terminate
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TerminationButton;
