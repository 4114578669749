import {
  BankIdStatusResponse,
  FailedStatus,
  PendingStatus
} from '../../../../../../api/external/bankId/types';

const hintCodeMessages = {
  RFA1: 'Starta BankID-appen.',
  RFA3: 'Åtgärden avbruten. Försök igen',
  RFA6: 'Åtgärden avbruten.',
  RFA8: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.',
  RFA9: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
  RFA13: 'Försöker starta BankID-appen.',
  RFA14B:
    'Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank.',
  RFA16:
    'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.',
  RFA17A:
    'BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller <a href="https://install.bankid.com" target="_blank" rel="noreferrer">https://install.bankid.com</a>',
  RFA21: 'Identifiering eller underskrift pågår.',
  RFA22: 'Okänt fel. Försök igen.',
  RFA23: 'Fotografera och läs av din ID-handling med BankID-appen.'
};

export const getBankIdStatusMessage = (
  status: BankIdStatusResponse['status'],
  hintCode: PendingStatus['hintCode'] | FailedStatus['hintCode']
) => {
  switch (status) {
    case 'pending':
      switch (hintCode) {
        case 'outstandingTransaction':
        case 'noClient':
          return hintCodeMessages.RFA1;
        case 'started':
          return hintCodeMessages.RFA14B;
        case 'userMrtd':
          return hintCodeMessages.RFA23;
        case 'userSign':
          return hintCodeMessages.RFA9;
        default:
          return hintCodeMessages.RFA21;
      }
    case 'failed':
      switch (hintCode) {
        case 'expiredTransaction':
          return hintCodeMessages.RFA8;

        case 'certificateErr':
          return hintCodeMessages.RFA16;
        case 'userCancel':
          return hintCodeMessages.RFA6;
        case 'cancelled':
          return hintCodeMessages.RFA3;
        case 'startFailed':
          return hintCodeMessages.RFA17A;
        default:
          return hintCodeMessages.RFA22;
      }
    default:
      return undefined;
  }
};

export default getBankIdStatusMessage;
