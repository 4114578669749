import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { DateTimeInput, Form, TextInput, useRecordContext } from 'react-admin';

import { CreditResourceDetails } from '../../../types';
import TerminationButton from './TerminationButton';

const PaymentProtectionInsurance = () => {
  const record = useRecordContext<CreditResourceDetails>();
  const { status, registrationDate, attachments, logs } = record.paymentProtectionInsurance;

  return (
    <Form>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box mb="1em" display="flex" justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Payment Protection Insurance
              </Typography>
              {status === 'Active' && <TerminationButton />}
            </Box>

            <Box display={{ xs: 'block', md: 'flex' }} gap={{ sm: '1em' }}>
              <TextInput
                source="paymentProtectionInsurance.status"
                label="Insurance Status"
                fullWidth
                defaultValue="-"
                InputProps={{
                  readOnly: true
                }}
                variant="outlined"
              />

              {registrationDate ? (
                <DateTimeInput
                  source="paymentProtectionInsurance.registrationDate"
                  label="Registration date"
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  variant="outlined"
                />
              ) : (
                <TextInput
                  source="paymentProtectionInsurance.registrationDate"
                  label="Registration Date"
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  defaultValue="-"
                  variant="outlined"
                />
              )}
            </Box>

            <Box display="flex" flexDirection="column" width="50%" mt="1em">
              <Typography variant="h6" gutterBottom>
                Logs
              </Typography>
              {logs && logs.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      {logs.map(log => (
                        <TableRow key={log.createdAt}>
                          <TableCell align="left">
                            {new Date(log.createdAt).toLocaleString(undefined, { hour12: false })}
                          </TableCell>
                          <TableCell align="left">{log.event}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1" color="gray" fontStyle="italic">
                  No logs to display
                </Typography>
              )}
            </Box>

            <Box mt="1em" display="flex" flexDirection="column" width="50%">
              <Typography variant="h6" gutterBottom>
                Attachments
              </Typography>
              {attachments && attachments.length > 0 ? (
                attachments.map(attachment => (
                  <Link
                    href={attachment.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    key={attachment.url}
                    width="fit-content"
                  >
                    {attachment.name}
                  </Link>
                ))
              ) : (
                <Typography variant="body1" color="gray" fontStyle="italic">
                  No attachments to display
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Form>
  );
};

export default PaymentProtectionInsurance;
