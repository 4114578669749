import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import { AppBar, Logout, UserMenu } from 'react-admin';

import { APPBAR_BACKGROUND } from '../Config';

const Title = styled(Typography)({
  flex: 1,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const Spacer = styled('span')({
  flex: 1
});

const CustomUserMenu = () => (
  <UserMenu>
    <Logout />
  </UserMenu>
);

const CustomAppBar = () => {
  return (
    <AppBar style={{ background: APPBAR_BACKGROUND }} userMenu={<CustomUserMenu />}>
      <Title variant="h6" color="inherit" id="react-admin-title" />
      <Spacer />
    </AppBar>
  );
};

export default CustomAppBar;
