import IconCancel from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { HttpError, RaRecord } from 'ra-core';
import React, { useRef, useState } from 'react';
import {
  AutocompleteArrayInput,
  Button,
  Confirm,
  Create,
  Form,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import { useQuery } from 'react-query';

import API from '../../../api';
import QUERY_KEYS from '../../../constants/QueryKeys';
import { CreditResourceDetails } from '../../../types';
import { formatCurrency } from '../../../utils';

type FormValues = {
  action: 'APPROVE' | 'DECLINE';
  declineReasons?: string[];
  productTierId?: string;
  riskLevel: string;
  comment: string;
};

// We receive a stringified array of decline codes from the API, that couldn't be changed because there's some wrongly
// formatted data in the database.
const parseExistingDeclineCodes = (existingDeclineCodes: string | null): string[] => {
  if (!existingDeclineCodes) {
    return [];
  }

  try {
    const parsed = JSON.parse(existingDeclineCodes);
    return Array.isArray(parsed) && parsed.every(code => typeof code === 'string') ? parsed : [];
  } catch (e) {
    return [];
  }
};

const ManualReviewButton = () => {
  const { id, agreement, user } = useRecordContext<CreditResourceDetails>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const submittedFormData = useRef<FormValues>();
  const [performManualReview, { isLoading: isCreateManualReviewLoading }] = useCreate<RaRecord, HttpError>();
  const refresh = useRefresh();
  const notify = useNotify();

  const { data: productTiers = [], isSuccess: isProductTiersSuccess } = useQuery(
    QUERY_KEYS.ukProductTiers,
    API.productTiers.getUKProductTiers,
    { staleTime: Infinity }
  );

  const { data: declineCodes = [], isSuccess: isDeclineCodesSuccess } = useQuery(
    QUERY_KEYS.ukManualReviewDeclineCodes,
    API.manualReview.getUKDeclineCodes,
    { staleTime: Infinity }
  );

  const handleSubmit = (data: FormValues) => {
    submittedFormData.current = data;
    setIsConfirmOpen(true);
  };

  const handleConfirm = () => {
    if (!submittedFormData.current) {
      throw new Error('No form data to submit');
    }

    void performManualReview(
      `agreements/${id}/action`,
      {
        data: submittedFormData.current
      },
      {
        onSuccess: () => {
          notify('Agreement has been manually reviewed!', { type: 'success' });
          setIsConfirmOpen(false);
          setIsDialogOpen(false);
          submittedFormData.current = undefined;
          refresh();
        },
        onError: error => {
          notify(error.body.error.message, { type: 'error' });
        }
      }
    );
  };

  const isButtonDisabled = !isProductTiersSuccess || !isDeclineCodesSuccess;

  const productTier = productTiers?.find(({ id }) => id === agreement?.productTierId);

  return (
    <>
      <Button
        variant="outlined"
        label="Manual Review"
        onClick={() => setIsDialogOpen(true)}
        disabled={isButtonDisabled}
      >
        <ErrorIcon />
      </Button>

      {!isButtonDisabled ? (
        <>
          <Dialog fullWidth open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <Create title="&nbsp;- Manual Review">
              <Form onSubmit={data => handleSubmit(data as FormValues)}>
                <DialogTitle>Manual review</DialogTitle>

                <DialogContent>
                  <Stack>
                    <DialogContentText mb={2}>
                      Pre-filled values are based on the result of the automatic assessment.
                    </DialogContentText>

                    <SelectInput
                      variant="outlined"
                      size="medium"
                      label="Status"
                      source="type"
                      choices={[
                        { id: 'APPROVE', name: 'Approved' },
                        { id: 'REJECT', name: 'Rejected' }
                      ]}
                      defaultValue={agreement?.status === 'Granted' ? 'APPROVE' : 'REJECT'}
                      resettable
                      validate={required()}
                    />

                    <AutocompleteArrayInput
                      variant="outlined"
                      size="medium"
                      label="Decline reason(s)"
                      source="declineCodes"
                      choices={declineCodes.map(({ code, description }) => ({
                        id: code,
                        name: `${code} - ${description}`
                      }))}
                      defaultValue={parseExistingDeclineCodes(agreement?.rejectCode)}
                    />

                    <SelectInput
                      variant="outlined"
                      size="medium"
                      label="Max limit approved"
                      source="productTierId"
                      choices={productTiers.map(({ id, creditLimit }) => ({
                        id,
                        name: formatCurrency(creditLimit, user?.country)
                      }))}
                      defaultValue={productTier?.id}
                      resettable
                      validate={required()}
                    />

                    <SelectInput
                      variant="outlined"
                      size="medium"
                      label="Risk Classification"
                      source="riskLevel"
                      choices={[
                        { id: 'MEDIUM', name: 'Medium' },
                        { id: 'HIGH', name: 'High' }
                      ]}
                      defaultValue={user?.riskLevel}
                      resettable
                      validate={required()}
                    />

                    <TextInput
                      variant="outlined"
                      size="medium"
                      source="comment"
                      multiline
                      rows={2}
                      validate={required()}
                    />
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <Button label="ra.action.cancel" onClick={() => setIsDialogOpen(false)}>
                    <IconCancel />
                  </Button>
                  <SaveButton />
                </DialogActions>
              </Form>
            </Create>
          </Dialog>

          <Confirm
            isOpen={isConfirmOpen}
            loading={isCreateManualReviewLoading}
            title="Confirm manual review"
            content="Are you sure you want to manually review this agreement?"
            onConfirm={handleConfirm}
            onClose={() => {
              setIsConfirmOpen(false);
              submittedFormData.current = undefined;
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default ManualReviewButton;
