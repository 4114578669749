import { Button, Card, createTheme } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import { Notification, defaultTheme, useLogin, useNotify } from 'react-admin';

import FairloLogo from '../assets/fairlo-logotype-green.svg';
import GoogleLogo from '../assets/google.svg';

const Main = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)'
});

const StyledCard = styled(Card)({
  minWidth: 300,
  marginTop: '6em',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '40px'
});

const GoogleIcon = styled('img')({
  width: '16px',
  marginRight: '8px'
});

const SubTitle = styled('span')({
  paddingTop: '8px',
  marginBottom: '40px',
  fontSize: '20px',
  fontWeight: 'bold'
});

const LoginPage = () => {
  const login = useLogin();
  const notify = useNotify();

  const handleClick = () => login({}).catch(() => notify('An error occurred'));

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <Main>
        <StyledCard>
          <img src={FairloLogo} alt="Fairlo Logo" height="50px" />
          <SubTitle>BackOffice</SubTitle>

          <Button onClick={handleClick} variant="outlined" size="medium">
            <GoogleIcon src={GoogleLogo} alt="google icon" />
            Sign in with Google
          </Button>
        </StyledCard>
        <Notification />
      </Main>
    </ThemeProvider>
  );
};

export default LoginPage;
