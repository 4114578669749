import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';
import { useMutation } from 'react-query';

import API from '../../../../../api';
import useDialog from '../../../../../hooks/useDialog';
import { CreditResourceDetails } from '../../../../../types';
import AuthenticationModal from './AuthenticationModal/AuthenticationModal';
import LegalGuardianModal, { FormValues } from './LegalGuardianModal';

const AuthenticationButton = () => {
  const record = useRecordContext<CreditResourceDetails>();
  const userId = record.user.id;
  const notify = useNotify();
  const [isLegalGuardianDialogOpen, openLegalGuardianDialog, closeLegalGuardianDialog] = useDialog();
  const [isAuthenticationDialogOpen, openAuthenticationDialog, closeAuthenticationDialog] = useDialog();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const { mutate: authenticateUser, data: authenticateUserData } = useMutation({
    mutationFn: API.user.authenticateUser,
    onSuccess: openAuthenticationDialog,
    onError: () => {
      notify('Something went wrong starting authentication', { type: 'error' });
    }
  });

  const { mutate: authenticateLegalGuardian, data: authenticateLegalGuardianData } = useMutation({
    mutationFn: API.user.authenticateLegalGuardian,
    onSuccess: openAuthenticationDialog,
    onError: () => {
      notify('Something went wrong starting authentication for legal guardian', { type: 'error' });
    }
  });

  const handleMenuClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpen(false);
    } else if (event.key === 'Escape') {
      setIsMenuOpen(false);
    }
  };

  const handleLegalGuardianSubmit = ({ nationalNumber, callInitiator }: FormValues) => {
    authenticateLegalGuardian({
      userId,
      callInitiator,
      legalGuardianNationalNumber: nationalNumber
    });
    closeLegalGuardianDialog();
  };

  const orderId = authenticateUserData?.orderId || authenticateLegalGuardianData?.orderId;

  return (
    <Box display="flex" alignItems="center" sx={{ gap: '8px', zIndex: 10 }}>
      <div ref={anchorRef}>
        <Button
          id="authenticate-button"
          aria-controls={isMenuOpen ? 'authenticate-menu' : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={() => setIsMenuOpen(prev => !prev)}
          label="Authenticate"
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
        >
          <PhonelinkRingIcon />
        </Button>
      </div>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  autoFocusItem={isMenuOpen}
                  id="authenticate-menu"
                  aria-labelledby="authenticate-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem disabled dense>
                    Who initiated the call?
                  </MenuItem>
                  <MenuItem onClick={() => authenticateUser({ userId, callInitiator: 'user' })}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>The customer</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => authenticateUser({ userId, callInitiator: 'RP' })}>
                    <ListItemIcon>
                      <BusinessIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Fairlo</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={openLegalGuardianDialog}>
                    <ListItemIcon>
                      <GroupIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Legal guardian</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {isLegalGuardianDialogOpen ? (
        <LegalGuardianModal onClose={closeLegalGuardianDialog} onSubmit={handleLegalGuardianSubmit} />
      ) : null}

      {isAuthenticationDialogOpen && orderId ? (
        <AuthenticationModal onClose={closeAuthenticationDialog} orderId={orderId} />
      ) : null}
    </Box>
  );
};

export default AuthenticationButton;
