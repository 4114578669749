import { Auth } from 'aws-amplify';
import { fetchUtils } from 'react-admin';

import { environment } from '../Config';
import dataProvider from './dataProvider';

async function getAuthorizationHeader() {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  // either id token or access token based on the API
  return session.getIdToken().getJwtToken();
}

export const httpClient = async (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const jwt = await getAuthorizationHeader();
  // Add your own headers here
  options.headers.set('authorization', jwt);
  return fetchUtils.fetchJson(url, options);
};

export default dataProvider(environment.apiRoot, httpClient);
