import { Card } from '@mui/material';
import Paper from '@mui/material/Paper';
import {
  Datagrid,
  DateField,
  Form,
  FunctionField,
  List,
  TextField,
  TopToolbar,
  useRecordContext,
  useRefresh
} from 'react-admin';

import { CreditResourceDetails, UserComment } from '../../../types';
import CreateComment from './components/CreateComment';

const Comments = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CreditResourceDetails>();

  return record?.userComment ? (
    <Form>
      <Card>
        <List
          exporter={false}
          pagination={false}
          component={Paper}
          title={<></>}
          actions={
            <>
              <TopToolbar>
                {record?.user.id ? <CreateComment userId={record?.user.id} onChange={refresh} /> : <></>}
              </TopToolbar>
            </>
          }
          queryOptions={{ enabled: false }}
        >
          <Datagrid
            data={record.userComment}
            bulkActionButtons={false}
            size="small"
            aria-label="Comments table"
            sx={{ minWidth: '650px' }}
          >
            <DateField source="createdAt" sortable={false} showTime options={{ hour12: false }} />
            <FunctionField
              source="comment"
              label="Comments"
              sortable={false}
              render={(userComment: UserComment) =>
                userComment.comment.includes('<!DOCTYPE html>') ? (
                  <div dangerouslySetInnerHTML={{ __html: userComment.comment }} />
                ) : (
                  userComment.comment
                )
              }
            />

            <TextField
              source="notificationEmail"
              label="Notification Email"
              defaultValue="-"
              sortable={false}
            />
            <TextField
              source="notificationPhoneNumber"
              label="Notification number"
              defaultValue="-"
              sortable={false}
            />
            <TextField source="commentBy" label="Comments by" sortable={false} />
          </Datagrid>
        </List>
      </Card>
    </Form>
  ) : null;
};

export default Comments;
