import { FunctionField, TextInput, TextInputProps } from 'react-admin';

import { formatCurrency } from '../utils';

const CurrencyInput = (restProps: TextInputProps) => (
  <FunctionField
    sx={{ width: '100%' }}
    render={(record: any) => {
      if (!record?.user) {
        return null;
      }

      return (
        <TextInput
          {...restProps}
          format={(amount: number) => {
            if (amount === undefined) {
              return '-';
            }

            return formatCurrency(amount, record.user.country);
          }}
        />
      );
    }}
  />
);

export default CurrencyInput;
