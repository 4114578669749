import React from 'react';
import { Edit, SelectInput, SimpleForm, Toolbar, SaveButton, Labeled, useRecordContext } from 'react-admin';

import { CreditResourceDetails } from '../types';

const STATUS_CHOICES = [
  { id: 'Granted', name: 'Granted' },
  { id: 'UnderTermination', name: 'UnderTermination' },
  { id: 'Investigation', name: 'Investigation' },
  { id: 'Fraud', name: 'Fraud' },
  { id: 'DebtReconstruction', name: 'DebtReconstruction' },
  { id: 'Deceased', name: 'Deceased' },
  { id: 'Test', name: 'Test' }
];

const CustomToolbar = () => (
  <Toolbar>
    <SaveButton type="button" transform={({ status }) => ({ status })} />
  </Toolbar>
);

const SelectStatus = () => {
  const record = useRecordContext<CreditResourceDetails>();
  const defaultValue = STATUS_CHOICES.find(statusEntry => statusEntry.id === record?.agreement.status);

  return (
    <Labeled label="Update credit status">
      <SelectInput source="status" defaultValue={defaultValue?.id} choices={STATUS_CHOICES} />
    </Labeled>
  );
};

const UpdateCreditStatus = () => (
  <Edit>
    <SimpleForm toolbar={<CustomToolbar />}>
      <SelectStatus />
    </SimpleForm>
  </Edit>
);

export default UpdateCreditStatus;
