import { Datagrid, DateField, Filter, List, ListProps, ShowButton, TextField, TextInput } from 'react-admin';

import CountryField from '../components/CountryField';
import CurrencyField from '../components/CurrencyField';
import useAuthCountries from '../hooks/useAuthCountries';

const CreditList = (props: ListProps) => {
  const authCountries = useAuthCountries();

  return (
    <List
      filters={<CreditFilter />}
      exporter={false}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" showTime options={{ hour12: false }} />
        <TextField source="contractId" />
        <TextField source="userName" />
        {authCountries.length > 1 ? <CountryField label="Country" /> : null}
        <CurrencyField label="Amount Granted" source="amountGranted" />
        <CurrencyField label="Amount Used" source="amountUsed" />
        <TextField source="status" />
        <TextField source="causeOfReject" emptyText=" " />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const CreditFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn fullWidth={true} />
  </Filter>
);

export default CreditList;
