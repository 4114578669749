import { ReactNode } from 'react';

import { Country } from '../types';

type Props = {
  countries: Country[];
  record?: { country: Country };
  children: ReactNode;
};

const RequireUserCountry = ({ countries, record, children }: Props) =>
  record && countries.includes(record.country) ? <>{children}</> : null;

export default RequireUserCountry;
