import { Payments as PaymentsIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { Button } from 'react-admin';

import useDialog from '../../../../../hooks/useDialog';
import Modal from './Modal';

const RegisterManualPayment = () => {
  const [isDialogOpen, openDialog, closeDialog] = useDialog();

  return (
    <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
      <Button onClick={openDialog} label="Register payment" variant="outlined">
        <PaymentsIcon />
      </Button>
      {isDialogOpen && <Modal onClose={closeDialog} />}
    </Box>
  );
};

export default RegisterManualPayment;
