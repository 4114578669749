import { environment } from '../../Config';
import { httpClient } from '../../dataprovider';
import { validateResponseData } from '../../utils/validateResponseData';
import { userAuthenticateByOrderIdResponseSchema, userAuthenticateResponseSchema } from './schemas';

type AuthenticateUserParams = {
  userId: string;
  callInitiator: 'RP' | 'user'; // RP refers to Relying Party as specified by BankID
};

export const authenticateUser = async ({ userId, callInitiator }: AuthenticateUserParams) => {
  const response = await httpClient(`${environment.apiRoot}/user/${userId}/authenticate`, {
    method: 'POST',
    body: JSON.stringify({
      callInitiator,
      authenticationType: 'USER'
    })
  });

  return validateResponseData(userAuthenticateResponseSchema, response.json);
};

type AuthenticateLegalGuardianParams = {
  userId: string;
  callInitiator: 'RP' | 'user'; // RP refers to Relying Party as specified by BankID
  legalGuardianNationalNumber: string;
};

export const authenticateLegalGuardian = async ({
  userId,
  callInitiator,
  legalGuardianNationalNumber
}: AuthenticateLegalGuardianParams) => {
  const response = await httpClient(`${environment.apiRoot}/user/${userId}/authenticate`, {
    method: 'POST',
    body: JSON.stringify({
      callInitiator,
      legalGuardianNationalNumber,
      authenticationType: 'LEGAL_GUARDIAN'
    })
  });

  return validateResponseData(userAuthenticateResponseSchema, response.json);
};

export const authenticateUserByOrderId = async ({ userId, orderId }: { userId: string; orderId: string }) => {
  const response = await httpClient(`${environment.apiRoot}/user/${userId}/authenticate/${orderId}`, {
    method: 'GET'
  });

  return validateResponseData(userAuthenticateByOrderIdResponseSchema, response.json);
};
