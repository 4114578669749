import { Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

import RequireUserCountry from '../../../../../components/RequireUserCountry';
import { CreditResourceDetails } from '../../../../../types';
import AddressSE from './SE';
import AddressUK from './UK';

const Address = () => {
  const record = useRecordContext<CreditResourceDetails>();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Address
      </Typography>

      <RequireUserCountry record={record.user} countries={['SE']}>
        <AddressSE />
      </RequireUserCountry>

      <RequireUserCountry record={record.user} countries={['UK']}>
        <AddressUK />
      </RequireUserCountry>
    </>
  );
};

export default Address;
