import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { HttpError } from 'ra-core';
import React from 'react';
import {
  Button,
  CheckboxGroupInput,
  Create,
  Form,
  required,
  SaveButton,
  TextInput,
  useCreate,
  useNotify
} from 'react-admin';
import { useFormState } from 'react-hook-form';

import useDialog from '../../../../hooks/useDialog';

type Comment = {
  id: string;
  userId: string;
  notification?: Array<'sms' | 'email'>;
  comment: string;
};

function CreateComment({ onChange, userId }: any) {
  const [isDialogOpen, openDialog, closeDialog] = useDialog();
  const [create, { isLoading }] = useCreate<Comment, HttpError>();
  const notify = useNotify();

  const { isValid } = useFormState();

  const handleSubmit = (data: Pick<Comment, 'notification' | 'comment'>) => {
    create(
      `user/${userId}/comment`,
      { data },
      {
        onSuccess: () => {
          closeDialog();
          onChange();
        },
        onError: error => {
          notify(error.body.error.message, { type: 'error' });
        }
      }
    );
  };

  return (
    <>
      <Button onClick={openDialog} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={isDialogOpen} onClose={closeDialog} aria-label="Create post">
        <DialogTitle>Create post</DialogTitle>
        <Create>
          <Form onSubmit={data => handleSubmit(data as Pick<Comment, 'notification' | 'comment'>)}>
            <DialogContent>
              <CheckboxGroupInput
                label="Notify customer?"
                source="notification"
                choices={[
                  { id: 'sms', name: 'SMS' },
                  { id: 'email', name: 'Email' }
                ]}
              />
              <TextInput source="comment" multiline resettable validate={required()} fullWidth />
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={closeDialog} disabled={isLoading}>
                <IconCancel />
              </Button>
              <SaveButton saving={isLoading} disabled={isLoading || !isValid} />
            </DialogActions>
          </Form>
        </Create>
      </Dialog>
    </>
  );
}

export default CreateComment;
