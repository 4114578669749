import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';

import App from './App';
import amplifyConfig from './config/amplifyConfig';
import './index.css';

Amplify.configure(amplifyConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
