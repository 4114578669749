import * as bankId from './external/bankId';
import * as manualPayments from './manualPayments';
import * as manualReview from './manualReview';
import * as paymentProtectionInsurance from './paymentProtectionInsurance';
import * as productTiers from './productTiers';
import * as user from './user';

const API = {
  external: {
    bankId
  },
  manualReview,
  productTiers,
  manualPayments,
  user,
  paymentProtectionInsurance
};

export default API;
