type DateFormat = 'YYYY-MM-DD' | 'YYYY-DD-MM HH:mm' | 'DD-MM-YY' | 'DD-MM';
/**
 * Formats date provided in the specified format
 * Default format: YYYY-MM-DD
 * @param date
 * @param dateFormat
 */
export const formatDate = (date: Date, dateFormat: DateFormat = 'YYYY-MM-DD') => {
  const DD = String(date.getDate()).padStart(2, '0');
  const MM = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const YYYY = date.getFullYear();
  switch (dateFormat) {
    case 'YYYY-MM-DD': {
      return `${YYYY}-${MM}-${DD}`;
    }
    case 'YYYY-DD-MM HH:mm': {
      const HH = date.getHours();
      const mm = String(date.getMinutes()).padStart(2, '0');
      return `${YYYY}-${DD}-${MM} ${HH}:${mm}`;
    }
    case 'DD-MM-YY': {
      return `${DD}-${MM}-${`${YYYY}`.slice(-2)}`;
    }
    case 'DD-MM': {
      return `${DD}-${MM}`;
    }
  }
  return `${YYYY}-${MM}-${DD}`;
};
