import { Box } from '@mui/material';
import React from 'react';
import { TextInput } from 'react-admin';

const AddressUK = () => (
  <>
    <TextInput source="user.address.coAddress" label="CO Address" multiline fullWidth />
    <TextInput source="user.address.address" label="Address" multiline fullWidth />
    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput source="user.address.postCode" label="Post code" fullWidth />
      </Box>
      <Box flex={2}>
        <TextInput source="user.address.town" label="Town" fullWidth />
      </Box>
    </Box>
    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput source="user.address.street" label="Street" fullWidth />
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput source="user.address.buildingName" label="Building name" fullWidth />
      </Box>
      <Box flex={1}>
        <TextInput source="user.address.buildingNumber" label="Building number" fullWidth />
      </Box>
    </Box>
  </>
);

export default AddressUK;
