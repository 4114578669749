import IconContentBlock from '@mui/icons-material/Block';
import IconCancel from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  Form,
  CREATE,
  useRecordContext
} from 'react-admin';

import RequireUserCountry from '../../../components/RequireUserCountry';
import dataProvider from '../../../dataprovider';
import { CreditResourceDetails } from '../../../types';
import { Block } from '../../../types/block';

type Props = {
  onChange: () => void;
};

type FormProps = Pick<Block, 'nationalNumber' | 'email' | 'comment'>;

function BlockUser({ onChange }: Props) {
  const record = useRecordContext<CreditResourceDetails>();
  const { nationalNumber, email } = record.user;
  const [showDialog, setShowDialog] = useState(false);
  const { isLoading } = useCreate('comment')[1];
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async ({ nationalNumber, email, comment }: FormProps) => {
    dataProvider(CREATE, 'block', { data: { nationalNumber, email, comment } })
      .then(() => {
        setShowDialog(false);
        onChange();
      })
      .catch((error: any) => {
        notify(error.message, { type: 'error' });
      });
  };

  return (
    <>
      <Button onClick={handleClick} label="Block user" disabled={record.blocked.blocked}>
        <IconContentBlock />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Block user">
        <DialogTitle>Block user</DialogTitle>
        <Form resource="user" onSubmit={data => handleSubmit(data as FormProps)}>
          <>
            <DialogContent>
              <RequireUserCountry record={record.user} countries={['SE']}>
                <TextInput
                  source="nationalNumber"
                  label="National number"
                  defaultValue={nationalNumber}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />
              </RequireUserCountry>
              <RequireUserCountry record={record.user} countries={['UK']}>
                <TextInput
                  source="email"
                  label="Email"
                  defaultValue={email}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />
              </RequireUserCountry>
              <TextInput source="comment" multiline resettable validate={required()} fullWidth />
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                <IconCancel />
              </Button>
              <SaveButton disabled={isLoading} />
            </DialogActions>
          </>
        </Form>
      </Dialog>
    </>
  );
}

export default BlockUser;
