import { environment } from '../../../Config';
import { httpClient } from '../../../dataprovider';
import { validateResponseData } from '../../../utils/validateResponseData';
import { bankIdStatusResponseSchema } from './schemas';

export const getBankIdStatus = async (orderId: string) => {
  const response = await httpClient(`${environment.clientApiRoot}/bankid/${orderId}/status`, {
    method: 'GET'
  });

  return validateResponseData(bankIdStatusResponseSchema, response.json);
};
