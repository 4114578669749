import { Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

import RequireUserCountry from '../../../../../components/RequireUserCountry';
import { CreditResourceDetails } from '../../../../../types';
import BankDetailsSE from './SE';
import BankDetailsUK from './UK';

const BankDetails = () => {
  const record = useRecordContext<CreditResourceDetails>();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Bank details
      </Typography>

      <RequireUserCountry record={record.user} countries={['SE']}>
        <BankDetailsSE />
      </RequireUserCountry>

      <RequireUserCountry record={record.user} countries={['UK']}>
        <BankDetailsUK />
      </RequireUserCountry>
    </>
  );
};

export default BankDetails;
