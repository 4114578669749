import { Box, Card, CardContent, Typography } from '@mui/material';
import { HttpError } from 'ra-core';
import { useCallback } from 'react';
import {
  DateTimeInput,
  Form,
  SaveButton,
  TextInput,
  Toolbar,
  email,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate
} from 'react-admin';
import { useFormState, useWatch } from 'react-hook-form';

import RequireUserCountry from '../../../components/RequireUserCountry';
import { CreditResourceDetails, User } from '../../../types';
import { dirtyValues } from '../../../utils/form';
import BlockUser from './BlockUser';
import Address from './components/Address';
import AuthenticationButton from './components/AuthenticationButton';
import BankDetails from './components/BankDetails';

const validateEmail = email();

const UpdateUserButton = ({ label }: { label: string }) => {
  const [update, { isLoading }] = useUpdate<User, HttpError>();
  const notify = useNotify();
  const user = useWatch({ name: 'user' }) as User;
  const refresh = useRefresh();
  const { dirtyFields } = useFormState();

  const handleClick = useCallback(() => {
    const userDirtyFields = dirtyFields.user;
    update(
      'user',
      {
        data: dirtyValues(userDirtyFields, user),
        id: user.id
      },
      {
        onSuccess: () => {
          notify('ra.notification.updated', { type: 'info', messageArgs: { smart_count: 1 } });
          refresh();
        },
        onError: error => {
          notify(error.body.error.message, { type: 'error' });
        }
      }
    );
  }, [dirtyFields.user, notify, refresh, update, user]);

  return <SaveButton saving={isLoading} label={label} onClick={handleClick} />;
};

const UserInfo = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CreditResourceDetails>();

  if (!record.user) {
    return null;
  }

  return (
    <Form>
      <Card>
        <CardContent>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
              {record?.blocked?.blocked ? (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    User blocked
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                    <Box flex={1}>
                      <DateTimeInput
                        source="blocked.createdAt"
                        label="Start date"
                        error
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                      />
                    </Box>
                    <Box flex={2}>
                      <TextInput
                        source="blocked.comment"
                        label="Comments"
                        fullWidth
                        error
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                        multiline
                      />
                    </Box>
                  </Box>
                  <Box mt="1em" />
                </Box>
              ) : null}
              <Box
                mb="1em"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ gap: '16px' }}
              >
                <Typography variant="h6" gutterBottom>
                  Identity
                </Typography>
                <AuthenticationButton />
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                <RequireUserCountry record={record.user} countries={['SE']}>
                  <Box flex={1}>
                    <TextInput
                      source="user.nationalNumber"
                      label="National number"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                </RequireUserCountry>
                <Box flex={1}>
                  <TextInput
                    source="user.id"
                    label="User ID"
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                <Box flex={1}>
                  <TextInput
                    source="user.givenName"
                    label="First name"
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                    variant="outlined"
                  />
                </Box>
                <Box flex={1}>
                  <TextInput
                    source="user.lastName"
                    label="Last name"
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                <Box flex={1}>
                  <TextInput source="user.mobile" label="Mobile" fullWidth />
                </Box>
                <Box flex={1}>
                  <TextInput
                    type="email"
                    source="user.email"
                    label="Email"
                    validate={validateEmail}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box mt="1em" />

              <Address />

              <BankDetails />
            </Box>
          </Box>
        </CardContent>
        <Toolbar resource="user">
          <UpdateUserButton label="Update user" />
          <BlockUser onChange={refresh} />
        </Toolbar>
      </Card>
    </Form>
  );
};

export default UserInfo;
