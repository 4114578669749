import { EditButton, Show, Tab, TabbedShowLayout, TopToolbar } from 'react-admin';

import Agreement from './Agreement';
import Comments from './Comments';
import PaybackPlan from './PaybackPlan';
import PaymentProtectionInsurance from './PaymentProtectionInsurance';
import Transactions from './Transactions';
import User from './User';

const CreditShow = () => (
  <Show actions={<CreditShowActions />} queryOptions={{ refetchOnWindowFocus: false }}>
    <TabbedShowLayout>
      <Tab label="AGREEMENT">
        <Agreement />
      </Tab>
      <Tab label="CUSTOMER" path="customer">
        <User />
      </Tab>
      <Tab label="INSURANCE" path="payment-protection-insurance">
        <PaymentProtectionInsurance />
      </Tab>
      <Tab label="TRANSACTIONS" path="transactions">
        <Transactions />
      </Tab>
      <Tab label="PAYBACKPLAN" path="payback-plan">
        <PaybackPlan />
      </Tab>
      <Tab label="COMMENTS" path="comments">
        <Comments />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const CreditShowActions = ({ data }: any) => (
  <TopToolbar>
    <EditButton label="Update status" record={data} />
  </TopToolbar>
);

export default CreditShow;
