import Icon from '@mui/icons-material/AttachMoney';
import { ResourceProps } from 'ra-core';

import CreditList from './CreditList';
import CreditShow from './CreditShow';
import UpdateCreditStatus from './UpdatedCreditStatus';

const credits: Omit<ResourceProps, 'name'> = {
  list: CreditList,
  edit: UpdateCreditStatus,
  show: CreditShow,
  icon: Icon
};

export default credits;
