import { Country } from '../types';

export const CountryConfig: Record<Country, { currency: string; flag: string; locale: 'sv-SE' | 'en-GB' }> = {
  SE: {
    currency: 'SEK',
    flag: '🇸🇪',
    locale: 'sv-SE'
  },
  UK: {
    currency: 'GBP',
    flag: '🇬🇧',
    locale: 'en-GB'
  }
} as const;

export const getCountryCurrency = (country: Country) => CountryConfig[country].currency;
export const getCountryFlag = (country: Country) => CountryConfig[country].flag;
export const getCountryLocale = (country: Country) => CountryConfig[country].locale;
