import { Cancel as CancelIcon, Search as SearchIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputBase,
  Paper,
  Button as MuiButton,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { HttpError } from 'ra-core';
import React, { useState } from 'react';
import { Button, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import API from '../../../../../api';
import { postManualPayment } from '../../../../../api/manualPayments';
import QUERY_KEYS from '../../../../../constants/QueryKeys';
import { CreditResourceDetails } from '../../../../../types';
import { formatDate } from '../../../../../utils/date';

const Dl = styled('dl')({
  display: 'grid',
  gridTemplateColumns: '110px 1fr',
  rowGap: '16px',
  width: '390px',
  margin: '40px auto'
});

const Dt = styled('dt')({ fontWeight: 'bold', textAlign: 'right' });

const Info = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '300px'
});

type FormValues = { aviNumber: string };

const Modal = ({ onClose }: { onClose: () => void }) => {
  const refresh = useRefresh();
  const [aviNumber, setAviNumber] = useState('');
  const record = useRecordContext<CreditResourceDetails>();
  const notify = useNotify();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<FormValues>({ mode: 'onBlur', defaultValues: { aviNumber: '' } });

  const {
    data: manualPayment,
    isLoading: isManualPaymentLoading,
    error: manualPaymentError
  } = useQuery({
    queryKey: QUERY_KEYS.manualPayment(aviNumber),
    queryFn: () => API.manualPayments.getManualPayment(aviNumber),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!aviNumber
  });

  const {
    mutate: registerManualPayment,
    isLoading: isRegisterManualPaymentLoading,
    isSuccess: isRegisterManualPaymentSuccess,
    reset: resetRegisterManualPayment
  } = useMutation(postManualPayment, {
    onSuccess: () => {
      notify('Payment registered!', { type: 'success' });
    },
    onError: error => {
      if (error instanceof HttpError) {
        notify(error.body?.message ?? 'Something went wrong', { type: 'error' });
      }
    }
  });

  const onSubmit = ({ aviNumber }: FormValues) => {
    resetRegisterManualPayment();
    setAviNumber(aviNumber);
  };

  let errorMessage = '';
  if (formErrors.aviNumber?.message) {
    errorMessage = formErrors.aviNumber.message;
  } else if (manualPaymentError instanceof HttpError) {
    errorMessage = manualPaymentError.status === 404 ? 'AVI number not found' : 'Something went wrong';
  }

  return (
    <Dialog
      fullWidth
      open={true}
      onClose={() => {
        refresh();
        onClose();
      }}
      aria-label="Register payment"
    >
      <DialogTitle>Register payment</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
            <InputBase
              {...register('aviNumber', {
                required: 'The AVI number is required'
              })}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search AVI number"
              inputProps={{ 'aria-label': 'search avi number' }}
              error={!!errorMessage}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          {!!errorMessage && (
            <FormHelperText sx={{ width: 400 }} error={true}>
              {errorMessage}
            </FormHelperText>
          )}
        </form>
        <Info>
          {isManualPaymentLoading && <p>Searching...</p>}
          {!isManualPaymentLoading && !manualPayment && <p>Please search for an AVI number</p>}
          {!!manualPayment && (
            <div style={{ width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Dl>
                <Dt>Amount:</Dt>
                <dd>
                  {manualPayment.payment.amount} {manualPayment.payment.currency}
                </dd>
                <Dt>FullName:</Dt>
                <dd>{manualPayment.fullName}</dd>
                <Dt>Address:</Dt>
                <dd>{manualPayment.address}</dd>
                <Dt>Reference:</Dt>
                <dd>{manualPayment.reference}</dd>
                <Dt>AVI number</Dt>
                <dd>{manualPayment.aviNumber}</dd>
                <Dt>Payment day:</Dt>
                <dd>{formatDate(new Date(manualPayment.paymentDate))}</dd>
              </Dl>
              {isRegisterManualPaymentSuccess && (
                <Alert>
                  Unknown payment has been successfully placed. Please delete it from{' '}
                  <strong>
                    <a
                      href="https://web1.prod.aptichosting.net/CapwayAB_8300/CapwayAB_Production_8310_ARC/ClientWeb/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      CapWay's system
                    </a>
                  </strong>
                </Alert>
              )}
            </div>
          )}
        </Info>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={onClose} disabled={false}>
          <CancelIcon />
        </Button>

        <MuiButton
          type="button"
          startIcon={<SaveIcon />}
          disabled={!manualPayment || isRegisterManualPaymentLoading || isRegisterManualPaymentSuccess}
          variant="contained"
          onClick={() => registerManualPayment({ aviNumber, agreementId: record.agreement.id })}
        >
          Register
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
