import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { DateTimeInput, Form, TextInput, TopToolbar, useRecordContext } from 'react-admin';

import CurrencyInput from '../../../components/CurrencyInput';
import ManualReviewButton from './ManualReviewButton';

const formatInterest = (val?: number) => (val ? `${parseFloat((val * 100).toFixed(2))}%` : '');

const Agreement = () => {
  const record = useRecordContext();

  return (
    <>
      {/* If more actions are added move this conditional rendering to the ManualReviewButton */}
      {record?.agreement?.underManualReview ? (
        <TopToolbar>
          <ManualReviewButton />
        </TopToolbar>
      ) : null}

      <Form>
        <Card>
          <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                {record?.agreement?.withdrawEligibilityCheckerResult &&
                  !record?.agreement?.withdrawEligibilityCheckerResult?.approve && (
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        Withdraw rejected
                      </Typography>
                      <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                        <Box flex={1}>
                          <TextInput
                            source="agreement.withdrawEligibilityCheckerResult.reason"
                            label="Reason"
                            fullWidth
                            error
                            InputProps={{
                              readOnly: true
                            }}
                            variant="outlined"
                            multiline
                          />
                        </Box>
                      </Box>
                      <Box mt="1em" />
                    </Box>
                  )}

                <Typography variant="h6" gutterBottom>
                  Agreement
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                  <Box flex={1}>
                    <TextInput
                      source="agreement.contractId"
                      label="Contract #"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source="agreement.status"
                      label="Status"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>

                  <Box flex={1}>
                    {record?.agreement?.signedDate ? (
                      <DateTimeInput
                        source="agreement.signedDate"
                        label="Start date"
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextInput
                        source="agreement.signedDate"
                        label="Start date"
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                        defaultValue="Not signed"
                      />
                    )}
                  </Box>
                </Box>
                {record?.agreement?.causeOfReject && (
                  <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                    <Box flex={1}>
                      <TextInput
                        source="agreement.causeOfReject"
                        label="Cause of reject"
                        resource="agreement"
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                )}
                <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                  <Box flex={1}>
                    <TextInput
                      source="agreement.annualInterest"
                      label="Interest"
                      fullWidth
                      format={formatInterest}
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source="agreement.effectiveInterest"
                      label="Effective interest"
                      format={formatInterest}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                  <Box flex={1}>
                    <TextInput
                      source="agreement.applicationType"
                      label="Application type"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <CurrencyInput
                      source="agreement.amountRequested"
                      label="Amount requested"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Box mt="1em" />

                <Typography variant="h6" gutterBottom>
                  Varda Balance
                </Typography>

                <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                  <Box flex={1}>
                    <CurrencyInput
                      source="agreement.amountGranted"
                      label="Granted"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <CurrencyInput
                      source="agreement.amountUsed"
                      label="Used"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                  <Box flex={1}>
                    <CurrencyInput
                      source="agreement.debtExclOverdue"
                      label="Current debt excluding overdue"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <CurrencyInput
                      source="agreement.debt"
                      label="Current debt"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>

                {record?.agreement?.debtConsolidation && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Lösa Balance
                    </Typography>

                    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                      <Box flex={1}>
                        <CurrencyInput
                          source="agreement.debtConsolidation.amountGranted"
                          label="Granted"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                          variant="outlined"
                        />
                      </Box>
                      <Box flex={1}>
                        <CurrencyInput
                          source="agreement.debtConsolidation.amountUsed"
                          label="Used"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
                      <Box flex={1}>
                        <CurrencyInput
                          source="agreement.debtConsolidation.debtExclOverdue"
                          label="Current debt excluding overdue"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                          variant="outlined"
                        />
                      </Box>
                      <Box flex={1}>
                        <CurrencyInput
                          source="agreement.debtConsolidation.debt"
                          label="Current debt"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  </>
                )}

                <Box mt="1em" />
                <Typography variant="h6" gutterBottom>
                  Log
                </Typography>
                {record?.agreementLog && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        {record.agreementLog.map(
                          (log: any) =>
                            !log.paybackPlanId && (
                              <TableRow key={log.id}>
                                <TableCell align="left">
                                  {new Date(log.createdAt).toLocaleString(undefined, { hour12: false })}
                                </TableCell>
                                <TableCell align="left">{log.event}</TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Box mt="1em" />
                <Typography variant="h6" gutterBottom>
                  Paymentplan log
                </Typography>
                {record?.agreementLog && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        {record.agreementLog.map(
                          (log: any) =>
                            log.paybackPlanId && (
                              <TableRow key={log.id}>
                                <TableCell align="left">
                                  {new Date(log.createdAt).toLocaleString(undefined, { hour12: false })}
                                </TableCell>
                                <TableCell align="left">{log.event}</TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Box mt="1em" />
                <Typography variant="h6" gutterBottom>
                  Attachments
                </Typography>
                {record?.agreement?.attachment &&
                  record.agreement.attachment.map((attachment: any, index: number) => (
                    <Link href={attachment.url} rel="noopener" key={index}>
                      {attachment.name + ' '}
                    </Link>
                  ))}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Form>
    </>
  );
};

export default Agreement;
