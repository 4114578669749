import { environment } from '../../Config';
import { httpClient } from '../../dataprovider';
import { ProductTiersResponse } from './types';

export const getUKProductTiers = async () => {
  const response = await httpClient(`${environment.apiRoot}/product-tiers?country=UK`, {
    method: 'GET'
  });

  return response.json as ProductTiersResponse;
};
