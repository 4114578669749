import React from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';

import { getCountryFlag } from '../localization/constants';
import { Country } from '../types';

const CountryField = (props: Omit<FunctionFieldProps, 'render'>) => (
  <FunctionField {...props} render={({ country }: { country: Country }) => getCountryFlag(country)} />
);

export default CountryField;
