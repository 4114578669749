import { HttpError } from 'ra-core';
import { useMutation, UseMutationOptions } from 'react-query';

import { environment } from '../Config';
import { httpClient } from '../dataprovider';

type UserAction = {
  type: 'RESET_PAYBACK_PLAN' | 'PAYMENT_FREE_MONTH' | 'OVERDUE_CAMPAIGN';
};

const useUserActionMutation = (userId: string, options?: UseMutationOptions<{}, HttpError, UserAction>) =>
  useMutation<{}, HttpError, UserAction>(
    data =>
      httpClient(`${environment.apiRoot}/user/${userId}/action`, {
        method: 'POST',
        body: JSON.stringify(data)
      }),
    options
  );

export default useUserActionMutation;
