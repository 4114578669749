import Icon from '@mui/icons-material/Block';
import { ResourceProps } from 'ra-core';

import BlockCreate from './BlockCreate';
import BlockEdit from './BlockEdit';
import BlockList from './BlockList';

const Block: Omit<ResourceProps, 'name'> = {
  list: BlockList,
  edit: BlockEdit,
  create: BlockCreate,
  icon: Icon
};

export default Block;
