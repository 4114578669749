import React from 'react';
import { Admin, Resource } from 'react-admin';

import LoginPage from './LoginPage';
import authProvider from './auth/authProvider';
import block from './block';
import credits from './credits';
import dataProvider from './dataprovider';
import { Layout } from './layout';

const App = () => (
  <React.StrictMode>
    <Admin
      loginPage={LoginPage}
      layout={Layout}
      title="Fairlo Backoffice"
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource name="credit" {...credits} />
      <Resource name="block" {...block} />
    </Admin>
  </React.StrictMode>
);

export default App;
