import { Datagrid, DateField, EditButton, Filter, List, TextField, TextInput } from 'react-admin';

import CountryField from '../components/CountryField';
import useAuthCountries from '../hooks/useAuthCountries';

const BlockList = (props: any) => {
  const authCountries = useAuthCountries();

  return (
    <List
      filters={<BlockFilter />}
      exporter={false}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" showTime options={{ hour12: false }} />
        <DateField source="deactivated" showTime options={{ hour12: false }} emptyText=" " />
        {authCountries.includes('SE') ? <TextField source="nationalNumber" /> : null}
        {authCountries.includes('UK') ? <TextField source="email" /> : null}
        {authCountries.length > 1 ? <CountryField label="Country" /> : null}
        <TextField source="comment" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const BlockFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn fullWidth={true} />
  </Filter>
);

export default BlockList;
