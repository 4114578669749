let apiRoot: string;
let backgroundColor: string;
let cognitoDomain: string;
let cognitoUserPoolId: string;
let cognitoUserPoolAppClientId: string;
let clientApiRoot: string;
let clientSiteUrl: string;
let env: 'production' | 'develop' | 'test' | 'local';

const hostname = window && window.location && window.location.hostname;

if (hostname.includes('localhost')) {
  env = 'local';
  apiRoot = 'https://develop.credit.api.fairlo.se/api/back-office';
  clientApiRoot = 'https://develop.credit.api.fairlo.se/api';
  clientSiteUrl = 'https://developapp.fairlo.se';
  backgroundColor = '#e30300';
  cognitoDomain = 'auth-326342842961-eu-west-1-develop-backoffice-auth.auth.eu-west-1.amazoncognito.com';
  cognitoUserPoolId = 'eu-west-1_JZQIj5aGR';
  cognitoUserPoolAppClientId = '48aa4nb8c6it4m8ntkt7r92bms';
} else if (hostname.includes('develop.backoffice.fairlo.se')) {
  env = 'develop';
  apiRoot = 'https://develop.credit.api.fairlo.se/api/back-office';
  clientApiRoot = 'https://develop.credit.api.fairlo.se/api';
  clientSiteUrl = 'https://developapp.fairlo.se';
  backgroundColor = '#e30300';
  cognitoDomain = 'auth-326342842961-eu-west-1-develop-backoffice-auth.auth.eu-west-1.amazoncognito.com';
  cognitoUserPoolId = 'eu-west-1_JZQIj5aGR';
  cognitoUserPoolAppClientId = '48aa4nb8c6it4m8ntkt7r92bms';
} else if (hostname.includes('test.backoffice.fairlo.se')) {
  env = 'test';
  apiRoot = 'https://test.credit.api.fairlo.se/api/back-office';
  clientApiRoot = 'https://test.credit.api.fairlo.se/api';
  clientSiteUrl = 'https://testapp.fairlo.se';
  backgroundColor = '#6181F7';
  cognitoDomain = 'auth-326342842961-eu-west-1-test-backoffice-auth.auth.eu-west-1.amazoncognito.com';
  cognitoUserPoolId = 'eu-west-1_WyAwMlgym';
  cognitoUserPoolAppClientId = '4frqg7ill8dv7hdgv6cm7p6u99';
} else {
  env = 'production';
  apiRoot = 'https://credit.api.fairlo.se/api/back-office';
  clientApiRoot = 'https://credit.api.fairlo.se/api';
  clientSiteUrl = 'https://app.fairlo.se';
  backgroundColor = '#1ECB3D';
  cognitoDomain = 'auth-957333209255-eu-west-1-prod-backoffice-auth.auth.eu-west-1.amazoncognito.com';
  cognitoUserPoolId = 'eu-west-1_3pkecKV5i';
  cognitoUserPoolAppClientId = '6802oi5gb7l9dm9fvnkv813i3a';
}

export const environment = {
  env,
  apiRoot,
  clientApiRoot,
  clientSiteUrl
};

export const APPBAR_BACKGROUND = backgroundColor;
export const AMPLIFY_CONFIG = {
  cognitoDomain,
  region: 'eu-west-1',
  cognitoUserPoolId,
  cognitoUserPoolAppClientId,
  appUrl: window.location.origin
};
