import { environment } from '../../Config';
import { httpClient } from '../../dataprovider';
import { DeclineReasonsResponse } from './types';

export const getUKDeclineCodes = async () => {
  const response = await httpClient(`${environment.apiRoot}/manual-review/decline-codes`, {
    method: 'GET'
  });

  return (response.json.data as DeclineReasonsResponse).UK;
};
