import { Cancel as CancelIcon } from '@mui/icons-material';
import LoginIcon from '@mui/icons-material/Login';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@mui/material';
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Button, HttpError, useNotify, useRecordContext } from 'react-admin';

import { environment } from '../../../../../../Config';
import { BankIdStatusResponse } from '../../../../../../api/external/bankId/types';
import BankIdLogo from '../../../../../../assets/bankid.svg';
import { CreditResourceDetails } from '../../../../../../types';
import CopyToClipboardComponent from './CopyToClipboard';
import { getBankIdStatusMessage } from './getBankIdStatusMessage';
import useBankIdStatusQuery from './hooks/useBankIdStatusQuery';
import useUsersAuthenticateByOrderIdQuery from './hooks/useUsersAuthenticateByOrderIdQuery';

const Info = styled('div')({
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '300px'
});

const statusColorMap: Record<BankIdStatusResponse['status'], string> = {
  pending: 'primary',
  failed: 'error',
  complete: green['800']
};

type Params = {
  orderId: string;
  onClose: () => void;
};

const AuthenticationModal = ({ onClose, orderId }: Params) => {
  const record = useRecordContext<CreditResourceDetails>();
  const userId = record.user.id;
  const notify = useNotify();

  const { data: bankIdStatus, error: bankIdStatusError } = useBankIdStatusQuery(orderId, {
    refetchInterval: data => (data?.status === 'pending' ? 1000 : false) // Polling should be 1sec based on bankID specs for QR code generation: https://www.bankid.com/en/utvecklare/guider/teknisk-integrationsguide/qrkoder
  });

  const {
    data: usersAuthenticateByOrderIdData,
    error: usersAuthenticateByOrderIdError,
    isLoading: isUsersAuthenticateByOrderIdLoading
  } = useUsersAuthenticateByOrderIdQuery(
    { userId, orderId },
    {
      enabled: bankIdStatus?.status === 'complete'
    }
  );

  useEffect(
    function handleBankIdFailed() {
      if (bankIdStatusError) {
        notify('Something went wrong in bankID status', { type: 'error' });
      }
    },
    [bankIdStatusError, notify]
  );

  useEffect(
    function handleUsersAuthenticateByOrderIdError() {
      if (usersAuthenticateByOrderIdError) {
        if (
          usersAuthenticateByOrderIdError instanceof HttpError &&
          usersAuthenticateByOrderIdError.body?.message
        ) {
          notify(usersAuthenticateByOrderIdError.body.message, {
            type: 'error'
          });
        } else {
          notify('Something went wrong in user authentication', { type: 'error' });
        }
      }
    },
    [usersAuthenticateByOrderIdError, notify]
  );

  const message =
    bankIdStatus?.status === 'pending' || bankIdStatus?.status === 'failed'
      ? getBankIdStatusMessage(bankIdStatus.status, bankIdStatus.hintCode)
      : undefined;

  const clientUrl = usersAuthenticateByOrderIdData?.authKey
    ? `${environment.clientSiteUrl}?auth_token=${usersAuthenticateByOrderIdData.authKey}`
    : undefined;

  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <DialogTitle>Authenticating user</DialogTitle>
      <DialogContent>
        <Info>
          <img src={BankIdLogo} alt="Fairlo Logo" height="100px" />
          <Typography
            variant="subtitle1"
            fontSize="18px"
            fontWeight="bold"
            mt="20px"
            color={bankIdStatus?.status ? statusColorMap[bankIdStatus.status] : 'grey'}
          >
            Status: {bankIdStatus?.status ?? 'Not started'}
          </Typography>

          {!!clientUrl ? (
            <>
              <Link mt="25px" target="_blank" href={clientUrl} rel="noreferrer">
                <Button label="Login" size="medium" variant="contained" endIcon={<LoginIcon />} />
              </Link>
              <Typography variant="body1" color="gray" my="10px">
                or
              </Typography>
              <CopyToClipboardComponent text={clientUrl} />
            </>
          ) : null}

          {message ? (
            <Typography variant="body1" mt="5px" textAlign="center">
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </Typography>
          ) : null}

          {bankIdStatus?.status === 'pending' || isUsersAuthenticateByOrderIdLoading ? (
            <CircularProgress style={{ marginTop: '30px' }} />
          ) : null}
        </Info>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.close" onClick={onClose}>
          <CancelIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthenticationModal;
