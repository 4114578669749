import { environment } from '../../Config';
import { httpClient } from '../../dataprovider';
import { ManualPaymentResponse } from './types';

export const getManualPayment = async (aviNumber: string) => {
  const response = await httpClient(`${environment.apiRoot}/manual-payments/${aviNumber}`, {
    method: 'GET'
  });

  return response.json as ManualPaymentResponse;
};

export const postManualPayment = ({ aviNumber, agreementId }: { aviNumber: string; agreementId: string }) =>
  httpClient(`${environment.apiRoot}/manual-payments/${aviNumber}`, {
    method: 'POST',
    body: JSON.stringify({ agreementId })
  });
