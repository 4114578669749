import { Box } from '@mui/material';
import React from 'react';
import { TextInput } from 'react-admin';

const BankDetailsUK = () => (
  <>
    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput
          source="user.paymentProvider"
          label="Payment provider"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.accountStatus"
          label="Account Status"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.manualPaymentReference"
          label="Manual payment Reference"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
    </Box>
    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.bankName"
          label="Bank"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.accountNumber"
          label="Account number"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.sortCode"
          label="Sort code"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex' }} gap={{ sm: '1em' }}>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.directDebitMandate"
          label="Direct debit mandate"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.directDebitStatus"
          label="Direct debit status"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
      <Box flex={1}>
        <TextInput
          source="user.bankDetails.repaymentDay"
          label="Repayment day"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          defaultValue="-"
        />
      </Box>
    </Box>
  </>
);

export default BankDetailsUK;
