export const dateValidator = (message: string) => (value: string) => {
  // Assumes value is "YYYYMMDD"
  if (!/^[0-9]{4}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value)) {
    return message;
  }

  const year = parseInt(value.slice(0, 4), 10);
  const month = parseInt(value.slice(4, 6), 10) - 1; // month for Date() is zero based
  const date = parseInt(value.slice(6, 8), 10);

  const d = new Date(year, month, date);

  const isValid = d.getFullYear() === year && d.getMonth() === month && d.getDate() === date;
  return isValid ? undefined : message;
};
