import { usePermissions } from 'ra-core';

import { COUNTRIES, Country } from '../types';

// Groups are formatted like this Auth-Backoffice-Develop-SE from Google Auth
const getCountries = (groups: string[]) =>
  groups
    .map(group => {
      const country = group.split('-').pop() as Country;
      if (Object.values(COUNTRIES).includes(country)) {
        return country;
      }
      return undefined;
    })
    .filter(g => g !== undefined) as Country[];

const useAuthCountries = (): Country[] => {
  const { isLoading: isPermissionsLoading, permissions } = usePermissions();

  if (isPermissionsLoading) {
    return [];
  }
  return getCountries(permissions.accessToken.payload['cognito:groups']);
};

export default useAuthCountries;
