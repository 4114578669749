import { AMPLIFY_CONFIG } from '../Config';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Auth: {
    region: AMPLIFY_CONFIG.region,
    userPoolId: AMPLIFY_CONFIG.cognitoUserPoolId,
    userPoolWebClientId: AMPLIFY_CONFIG.cognitoUserPoolAppClientId,
    oauth: {
      domain: AMPLIFY_CONFIG.cognitoDomain,
      scope: ['phone', 'email', 'openid', 'profile'],
      redirectSignIn: AMPLIFY_CONFIG.appUrl,
      redirectSignOut: AMPLIFY_CONFIG.appUrl,
      responseType: 'code', // or token

      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  }
};
